import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const PageSEO = dynamic(() => import('@/components/SEO/PageSEO'));

export default function HeadTag({ data, title, type }) {
    const [themeColor, setThemeColor] = useState('#ffffff');

    useEffect(() => {
        // Initial theme detection
        const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const localStorageTheme = localStorage.getItem('theme');

        if (localStorageTheme === 'dark' || darkModeQuery.matches) {
            setThemeColor('#000000');
        } else {
            setThemeColor('#ffffff');
        }

        const handleThemeChange = e => {
            if (e.matches) {
                setThemeColor('#000000');
            } else {
                setThemeColor('#ffffff');
            }
        };

        // Listen for theme changes
        darkModeQuery.addListener(handleThemeChange);

        // Clean up the listener on component unmount
        return () => darkModeQuery.removeListener(handleThemeChange);
    }, []);

    return (
        <>
            <PageSEO title={title} data={data} type={type} />
            <Head>
                {/* ... rest of your head content ... */}
                <meta name="theme-color" content={themeColor} />
                <meta name="msapplication-TileColor" content={themeColor} />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                {/* ... rest of your head content ... */}
            </Head>
        </>
    );
}
